
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { watch } from 'vue-demi';
import RecordTable from "@/components/coupon-set/RecordTable.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import VueTagsInput from "@sipec/vue3-tags-input";

export default defineComponent({
  components: {
    Grid,
    RecordTable,
    SelectBox,
    VueTagsInput,
  },
  props: {
    sid: Number,
    paperType: Number,
    modelValue: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model
    const grid = ref<any>({});
    const isDisabled = ref(false);
    const gridOptions: GridOptions = {
      title: '發行紀錄',
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      multiselect: false,
      showFooter: true,
      columns: [
        { field: 'CreatedTime', title: '建立時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') : '' },
        { field: 'Subject', title: '主旨', showOverflow: true, sortable: true, resizable: false },
        { field: 'Message', title: '發送訊息', showOverflow: true, sortable: true, resizable: false },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "50%", height: "550", showFooter: true },
    }


    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
      { field: 'CreatedTime', title: '建立時間', span: 12, itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd HH:mm:ss' } } },
        { field: 'Id', title: '資料ID', span: 12, itemRender: { name: '$input' } },
        { field: 'Subject', title: '主旨', span: 24, itemRender: { name: '$input' } },
        { field: 'Message', title: '發送訊息', span: 24, itemRender: { name: '$input' } },
        { field: 'Recipient', title: '發送Email', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 4, type: 'string' } }, attrs: { type: 'textarea' } } },
        { field: 'Body', title: '郵件內容', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 4, type: 'string' } }, attrs: { type: 'textarea' } } },
        ],
      rules:{
        Name: [{ type: 'string', required: true }],
        Date: [{ type: 'string', required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      callback();
    }

    const deleteAll = () => {
      if(confirm("確定要清除折扣優惠列表嗎?")) {
        if(props.sid) {        
          model?.dispatch('coupon/deleteAll', props.sid).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "刪除所有折扣優惠",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          })
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得折扣資料",
          });
        }
      }
    }

    const send = () => {
      if(confirm("確定要發行嗎?")) {
        if(props.sid) {
          model?.dispatch('coupon/send', props.sid).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          })
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得折扣資料",
          });
        }
      }
    }

    const singleSend = (id: any) => {
      if(confirm("確定要發行折扣優惠嗎?")) {
        if(id) {
          model?.dispatch('coupon/singleSend', id).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          })
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得折扣資料",
          });
        }
      }
    }
    
    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇會員",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      formatText: (row) => row?.Name ? row.Name : row.Organization?.Name,
      columns: [
        {
          field: "Account",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ row, cellValue }) => cellValue ?? row.Organization?.Name,
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params => model!.dispatch("member/options", params) // eslint-disable-line
      }
    };

    const tags = ref([]);
    const customeEmail = ref('');
    
    const table = reactive({
      data: {} as any,
      isLoading: false,
      isEditing: false,
      formData: { id: [], members: [], type: 0, activityId: 0 } as any,
      selectedRow: null as any | null,
      async save() {
        //活動判斷
        // if(props.sid == 2 && table.formData.activityId <= 0) {
        //   CloudFun.send("error", {
        //     subject: "執行失敗",
        //     content: "請選擇活動！",
        //   });
        //   return;
        // }

        if(table.formData.type == 1 && table.formData.members.length <= 0 && tags.value.length <= 0) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "請選擇會員或輸入Email！",
          });
          return;
        }
        var emails = [] as any;
        if(tags.value && tags.value.length > 0) {
          emails = tags.value.filter((e: any) => e.text).map((e: any) => e.text);
        }
        const data = {
          id: props.sid,
          memberIds: table.formData.members,
          emails: emails,
          activityId: table.formData.activityId,
          typeId: table.formData.type,
        }
        console.log(data);
        isDisabled.value = true;
        await model?.dispatch('epaper/send', data).then(() => {
          CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
            isDisabled.value = false;
          }).catch((error) =>{
            console.log(error)
            isDisabled.value = false;
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
         })
      },
    })

    const getActivity = async(id: any) => {
      var params = {
        condition: new Condition('Published', Operator.Equal, true).and('EpaperId', Operator.IsNotNull).and('EpaperId.Value', Operator.Equal, id.toString())
      };
      return model?.dispatch('activity/options', params).then((data) => {
        return data;
      }).catch((error) =>{
        console.log(error)
      })
    }


    const form = ref<any>();
    const list = ref<any[]>([]);
    onMounted(async()=> {
      table.formData.activityId = 0;
      if(props.paperType == 2) {
        list.value = await getActivity(props.sid);
        console.log(list.value);
      }
    })

    watch(() => props.modelValue, async (val) => {
      //console.log(val)
      if(val) {
        table.formData.id = [];
        table.formData.members = [];
        table.formData.type = 0;
        table.formData.activityId = 0;
        customeEmail.value = '';
        tags.value = [];
        if(props.paperType == 2) {
          list.value = await getActivity(props.sid);
          console.log(list.value);
        }
      }
    })


    const onGridEdit = (row: any, callback: any) => {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    }

    const changeType = () => {
      if(table.formData.type == 0) {
        table.formData.members = [];
        customeEmail.value = '';
        tags.value = [];
      } else {
        table.formData.id = [];
      }
    }

    const close = () => {
      grid.value.isModalPopup = false;
    }

    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      deleteAll,
      send,
      singleSend,
      list,
      form,
      table,
      onGridEdit,
      changeType,
      memberSelectorOptions,
      customeEmail,
      close,
      tags,
      isDisabled,
      validation: [{
        classes: 'avoid-item',
        rule: (customeEmail: { text: string|any[]; }) => !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(customeEmail.text.toString())),
        disableAdd: true,
      }],
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },    
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
