
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString';
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import "@/global-components/ckeditor/styles.css";
import SendTable from "@/components/epaper/SendTable.vue";

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SelectBox,
    SendTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const enums = CloudFun.current?.enums as any;
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    const uploader = ref<any>({});

    const gridOptions: GridOptions = {
      stripe: false,
      title: '電子報',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '電子報清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '電子報清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [{
        field: 'Subject',
        title: '電子報主旨',
        width: '45%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: 'Version', title: '版本編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
      { field: 'PublishedDate', title: '發布日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
      { field: 'Published', title: '已發布', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      // {
      //   title: 'test',
      //   width: '100',
      //   slots: { default: "test" }
      // },
      {
        title: '設定',
        width: '100',
        slots: { default: "send" }
      },
      {
        title: '預覽',
        width: '100',
        slots: { default: "preview" }
      },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          return model.dispatch('epaper/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('epaper/query') : undefined,
        save: model ? (params) => model.dispatch('epaper/save', params) : undefined
      },
      modalConfig: { width: "70%", height: "700", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const showEdit = (row: any) => {
      if (row.data.Type == 1) return true;
      return false;
    }

    const changeType = (data: any) => {
      if(data.Type == 1) {
        setTemplate(data);
      } else {
        data.Content.Content = "";
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Subject',
          title: '電子報主旨',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '系統自動產生', clearable: true, disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'Version',
          title: '版本編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'Type',
        //   title: '電子報類型',
        //   span: 12,
        //   itemRender: { name: "$select", options: model ? Object.values(model.enums.EpaperType).map(e => { return { label: e.Name, value: e.Value } }) : [], events: { change: (e: any) => changeType(e.data) } }
        // },
        {
          field: 'PublishedDate',
          title: '發布日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'NoticedTime',
          title: '傳送時間',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'Published',
          title: '已發布',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'OperatorId',
          title: '操作人員',
          span: 12,
          slots: { default: "column-user-id" }
        },
        {
          field: "Photo.Uri",
          title: "橫幅圖片",
          span: 24,
          slots: { default: "column-photo-and-remark" }
          ,visibleMethod: showEdit
        },
        {
          field: 'LinkUrl',
          title: '橫幅鏈結網址',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入網址', clearable: true }, attrs: { type: 'text' } }
          ,visibleMethod: showEdit
        },
        {
          field: 'span-text',
          span: 24,
          slots: { default: "span-text" }
        },    
        // {
        //   field: 'MainBulletinId',
        //   title: '強打報告',
        //   span: 24,
        //   slots: { default: "column-publication" }
        //   ,visibleMethod: showEdit
        // },
        // {
        //   field: 'BulletinId',
        //   title: '精選文章',
        //   span: 24,
        //   slots: { default: "column-bulletin" }
        //   ,visibleMethod: showEdit
        // },
        {
          field: 'BulletinIds',
          title: '會訊文章',
          span: 24,
          slots: { default: "column-bulletins" }
          ,visibleMethod: showEdit
        },
        {
          field: 'NewsBulletinIds',
          title: '最新消息',
          span: 24,
          slots: { default: "column-news" }
          ,visibleMethod: showEdit
        },
        {
          field: 'ConferenceBulletinIds',
          title: '會務訊息',
          span: 24,
          slots: { default: "column-conference" }
          ,visibleMethod: showEdit
        },
        {
          field: 'AlbumListIds',
          title: '活動集錦',
          span: 24,
          slots: { default: "column-albums" }
          ,visibleMethod: showEdit
        },
        // {
        //   field: 'NewPublicationIds',
        //   title: '最新報告',
        //   span: 24,
        //   slots: { default: "column-new-publication" }
        //   ,visibleMethod: showEdit
        // },
        // {
        //   field: 'MediaIds',
        //   title: '影音專區',
        //   span: 24,
        //   slots: { default: "column-media" }
        //   ,visibleMethod: showEdit
        // },
        // {
        //   field: 'BookIds',
        //   title: '專書精選',
        //   span: 24,
        //   slots: { default: "column-book" }
        //   ,visibleMethod: showEdit
        // },
        // {
        //   title: '版型套用',
        //   span: 24,
        //   slots: { default: "column-template" }          
        // },
        { span: 24, slots: { default: 'html-editer' }, visibleMethod: (row: any) => row.data && row.data.Type == 2 ? true : false },
      ],
      rules: {
        Subject: [{ type: 'string', required: true }],
        PublishedDate: [{ required: true }],
        // Author: [{ type: 'string', required: true }],
        // LinkUrl: [{ type: 'string', max: 2000, required: true }],
      }
    }

    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      },
      htmlEmbed: {
            showPreviews: true,
            sanitizeHtml: ( inputHtml: any ) => {
                // Strip unsafe elements and attributes, e.g.:
                // the `<script>` elements and `on*` attributes.
                //const outputHtml = sanitize(inputHtml);
                //console.log(inputHtml)
                return {
                    html: inputHtml,
                    // true or false depending on whether the sanitizer stripped anything.
                    hasChanged: true
                };
            }
        }
    };

    const publicationSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "PublishedDate", title: "出版日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("publication/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Status', Operator.Equal, 1).and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "PublishedDate", order: 1 }];
          return await model!.dispatch("publication/query", params)
        }
      },
    };

    const bulletinSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "Date", title: "日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Author", title: "作者", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("bulletin/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Published', Operator.Equal, true).and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "Date", order: 1 }];
          return await model!.dispatch("bulletin/query", params)
        }
      },
    };

    const bulletinsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      multiselect: true,
      columns: [
        { field: "Date", title: "日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Author", title: "作者", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("bulletin/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Published', Operator.Equal, true).and('DataMode', Operator.Equal, 0).and('Type', Operator.Equal, 3)
          params.sortings = [{ column: "Date", order: 1 }];
          return await model!.dispatch("bulletin/query", params)
        }
      },
    };

    const newsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      multiselect: true,
      columns: [
        { field: "Date", title: "日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Author", title: "作者", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("bulletin/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Published', Operator.Equal, true).and('DataMode', Operator.Equal, 0).and('Type', Operator.Equal, 0)
          params.sortings = [{ column: "Date", order: 1 }];
          return await model!.dispatch("bulletin/query", params)
        }
      },
    };

    const conferenceSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      multiselect: true,
      columns: [
        { field: "Date", title: "日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Author", title: "作者", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("bulletin/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Published', Operator.Equal, true).and('DataMode', Operator.Equal, 0).and('Type', Operator.Equal, 1)
          params.sortings = [{ column: "Date", order: 1 }];
          return await model!.dispatch("bulletin/query", params)
        }
      },
    };

    const albumSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      multiselect: true,
      columns: [
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("pictureAlbum/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Published', Operator.Equal, true).and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "Ordinal", order: 0 }];
          return await model!.dispatch("pictureAlbum/query", params)
        }
      },
    };
    

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("user/find", value), // eslint-disable-line
        query: async(params) => await model!.dispatch("user/query", params) // eslint-disable-line
      }
    };

    const test = (id: any) => {
      if(confirm("確定要發行嗎?")) {
        if(id) {
          model?.dispatch('epaper/send', id).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            //grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          })
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得折扣資料",
          });
        }
      }
    }

    const bookSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "PublishedDate", title: "出版日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("book/find", value), // eslint-disable-line
        query: async(params) => {
          if (params.condition) params.condition.and('Published', Operator.Equal, true)
          else params.condition = new Condition('Published', Operator.Equal, true)
          params.condition.and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "PublishedDate", order: 1 }];
          return await model!.dispatch("book/query", params)
        },
      },
    };

    const mediaSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "PublishedDate", title: "出版日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("media/find", value), // eslint-disable-line
        query: async(params) => {
          if (params.condition) params.condition.and('Published', Operator.Equal, true)
          else params.condition = new Condition('Published', Operator.Equal, true)
          params.condition.and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "PublishedDate", order: 1 }];
          return await model!.dispatch("media/query", params)
        },
      },
    };
    
    const newPublicationSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "PublishedDate", title: "出版日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("publication/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Status', Operator.Equal, 1).and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "PublishedDate", order: 1 }];
          return await model!.dispatch("publication/query", params)
        }
      },
    };

    const industryNewSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "PublishedDate", title: "出版日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("industryNews/find", value), // eslint-disable-line
        query: async(params) => {
          if (params.condition) params.condition.and('Published', Operator.Equal, true)
          else params.condition = new Condition('Published', Operator.Equal, true)
          params.condition.and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "PublishedDate", order: 1 }];
          return await model!.dispatch("industryNews/query", params)
        },
      },
    };

    const setActivityTemplate = (data: any) => {
      if(data.Content) {
        data.Content.Content = `<table>
                                        <tbody>
                                            <tr>
                                                <td style="text-align:center;">
                                                    <p style="margin-left:0;">
                                                        <a href="@網頁版本" target="_blank"><span style="color:#333333;">網頁連結</span></a>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>`;
      }
    }

    const setTemplate = (data: any) => {
      if(data.Content) {
        data.Content.Content = `<div class="raw-html-embed">
    <table class="layout__wrapper" align="center" width="100%" border="0" cellspacing="0" cellpadding="0">
        <tbody>
            <tr class="layout__row">
                <td class="layout__column cLayout--bgColor" align="center" width="100%"
                    style="padding-left:10px;padding:10px;" bgcolor="#ffffff">
                    <div style="margin:0 auto;width:100%;max-width:1200px;">
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0"
                            cellpadding="0">
                            <tbody>
                              <tr>
                                <td class="w_50" align="left" valign="top" width="50%" >
                                  <div style="color:#6a6a6a;font-weight:400;font-size:18px;font-family:'PingFang TC','微軟正黑體','Microsoft JhengHei','Helvetica Neue',Helvetica,Arial,sans-serif;padding:0;margin:0;line-height:1.4">
                                        <span class="a__text" style="color: #EA5514; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                            @發行日期
                                        </span>
                                        發行
                                    </div>
                                </td>                                
                              </tr>
                              <tr>
                                <td class="block-inner__content cBlock--spacingLR w_50" align="right" valign="top" width="50%" bgcolor="#ffffff">
                                  <h4 style="color:#6a6a6a;font-weight:400;font-size:18px;font-family:'PingFang TC','微軟正黑體','Microsoft JhengHei','Helvetica Neue',Helvetica,Arial,sans-serif;padding:0;margin:0;line-height:1.4">
                                    如無法正常顯示 請點選
                                    <a href="@網頁版本" rel="noopener noreferrer" target="_blank" style="font-size: 16px; color: #6a6a6a; text-decoration: underline;">
                                        <span class="a__text" style="color: #6a6a6a; text-decoration: underline; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                網頁版本
                                        </span>
                                    </a>
                                  </h4>
                                </td>
                              </tr>
                              <tr>
                                  <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                      width="100%" bgcolor="#95cef4" style="padding-top:8px;">
                                  </td>
                              </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0"
                            cellpadding="0">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:8px;padding-bottom:8px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td align="center" valign="top" width="100%">
                                                      <a href="#" rel="noopener noreferrer" target="_blank"
                                                          style="size: 16px; color: #333333; text-decoration: none;">
                                                          <span class="a__text"
                                                              style="color: #333333; text-decoration: none; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                              <img class="img_block" border="0" width="1200"
                                                                  src="/Content/images/logo.png"
                                                                  style="display:block;max-width:40%;">
                                                          </span>
                                                      </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0"
                            cellpadding="0">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:8px;padding-bottom:8px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td align="center">
                                                        <p
                                                            style="color:#333333;font-weight:400;font-size:18px;font-family:'PingFang TC','微軟正黑體','Microsoft JhengHei','Helvetica Neue',Helvetica,Arial,sans-serif;padding:0;margin:0;line-height:1.4">
                                                            <a href="@網站連結" rel="noopener noreferrer" target="_blank"
                                                                style="size: 16px; color: #333333; text-decoration: none;">
                                                                <span class="a__text"
                                                                    style="color: #333333; text-decoration: none; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                    首頁 </span> </a>｜ <a href="@聯絡我們"
                                                                rel="noopener noreferrer" target="_blank"
                                                                style="size: 16px; color: #333333; text-decoration: none;">
                                                                <span class="a__text"
                                                                    style="color: #333333; text-decoration: none; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                    聯絡我們 </span> </a>｜<br class="sm_none"
                                                                style="display:none;"> <a href="@取消訂閱"
                                                                rel="noopener noreferrer" target="_blank"
                                                                style="size: 16px; color: #333333; text-decoration: none;">
                                                                <span class="a__text"
                                                                    style="color: #333333; text-decoration: none; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                    取消訂閱 </span> </a><br class="sm_none"
                                                                style="display:none;"> </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0"
                            cellpadding="0">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:8px;padding-bottom:8px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td align="left" valign="top" width="100%"> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-bottom: 16px;">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td align="center" valign="top" width="100%"> <a href="@本週強打.連結"
                                                            rel="noopener noreferrer" target="_blank"
                                                            style="size: 16px; color: #333333; text-decoration: none;">
                                                            <span class="a__text"
                                                                style="color: #333333; text-decoration: none; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                <img class="img_block" border="0" width="1200"
                                                                    src="@本週強打.圖片"
                                                                    style="display:block;max-width:100%;"> </span> </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>                        
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0" cellpadding="0" style="border-top: 2px solid #cccccc;">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR w_50" align="left" valign="top"
                                        width="50%" bgcolor="#ffffff" style="padding-top:8px;padding-bottom:0px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            style="padding-left: 12px;">
                                            <tbody>
                                                <tr>
                                                    <td align="left" valign="top" width="100%"> <a href="@新聞熱搜.連結"
                                                            rel="noopener noreferrer" target="_blank"
                                                            style="size: 26px; color: #333333; text-decoration: underline;">
                                                            <span
                                                                style="font-size: 26px;font-style: italic; color: #333333; text-decoration: underline; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                最新消息 </span> </a>
                                                        <ul style="font-size: 18px; color: #333333; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;"> @新聞熱搜 </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td class="block-inner__content cBlock--spacingLR w_50" align="left" valign="top"
                                        width="50%" bgcolor="#ffffff" style="padding-top:8px;padding-bottom:0px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            style="padding-left: 12px;">
                                            <tbody>
                                                <tr>
                                                    <td align="left" valign="top" width="100%"> <a href="@最新報告.連結"
                                                            rel="noopener noreferrer" target="_blank"
                                                            style="size: 26px; color: #333333; text-decoration: underline;">
                                                            <span
                                                                style="font-size: 26px;font-style: italic; color: #333333; text-decoration: underline; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                會務訊息 </span> </a>
                                                        <ul style="font-size: 18px; color: #333333; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;""> @最新報告 </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0" cellpadding="0" style="border-top: 2px solid #cccccc;">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR w_50" align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:32px;padding-bottom:16px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            style="padding-left: 12px;">
                                            <tbody>
                                                <tr>
                                                    <td align="left" valign="top" width="100%"> <a href="@影音專區.連結"
                                                            rel="noopener noreferrer" target="_blank"
                                                            style="size: 26px; color: #333333; text-decoration: underline;">
                                                            <span
                                                                style="font-size: 26px;font-style: italic; color: #333333; text-decoration: underline; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                活動集錦 </span> </a> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:32px;padding-bottom:16px">
                                        @影音專區 </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0" cellpadding="0" style="border-top: 2px solid #cccccc;">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR" align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:32px;padding-bottom:16px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            style="padding-left: 12px;">
                                            <tbody>
                                                <tr>
                                                    <td align="left" valign="top" width="100%"> <a href="@專書精選.連結"
                                                            rel="noopener noreferrer" target="_blank"
                                                            style="size: 26px; color: #333333; text-decoration: underline;">
                                                            <span
                                                                style="font-size: 26px;font-style: italic; color: #333333; text-decoration: underline; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                會訊文章 </span> </a> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:32px;padding-bottom:70px">
                                        @專書精選 </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="block-inner__content cBlock--spacingLR " align="center" valign="top" width="100%" bgcolor="#4F4F4F" style="padding: 10px;">
                              <span style="color: #ffffff; font-size: 15px; ">台灣能源技術服務產業發展協會 版權所有 © 2024 TAESCO All Rights Reserved.</span>
                            </td>
                          </tr>
									      </table>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>`
      }
      //classicEditor.editorRef.execute( 'htmlEmbed', '<b>New content.</b>' );
    }

    const sendGrid = ref<any>()
    const sendModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              // .and("EpaperId", Operator.Equal, sendModal.selectedRow.Id.toString())
              .and("DataId", Operator.Equal, sendModal.selectedRow.Id.toString())
              .and("Type", Operator.Equal, enums.MailType.Epaper.Value)
              .and(params.condition!);
             if (!params.sortings || params.sortings?.length === 0)
               params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("mailLog/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('mailLog/query') : undefined,
          save: model ? (params) => model.dispatch('mailLog/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        sendModal.visible = true;
        sendModal.selectedRow = row;
        if (sendGrid.value) sendGrid.value.refresh();
      }
    })

    const previewEpaper = async(id: any) => {
      const url = await model?.dispatch(`epaper/preview`, id).then((data) => {
          return data;
        }).catch((error) =>{
          console.log(error)
        });
      if(url) window.open(url);
    }

    const headers = {
      Authorization: `Basic ${model?.getCertificate(0)}`
    };
    
    return {
      model,
      grid,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      uploader,
      editorConfig,
      classicEditor,
      userSelectorOptions,
      publicationSelectorOptions,
      mediaSelectorOptions,
      bookSelectorOptions,
      newPublicationSelectorOptions,
      industryNewSelectorOptions,
      headers,
      test,
      setTemplate,
      setActivityTemplate,
      sendGrid,
      sendModal,
      previewEpaper,
      bulletinSelectorOptions,
      bulletinsSelectorOptions,
      newsSelectorOptions,
      conferenceSelectorOptions,
      albumSelectorOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("epaper/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
      }
      callback();
    }
  }
})
