<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">電子報</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="(row, callback) => { row.Published = false; row.Type = 1; row.Content = {Content: ''}; setTemplate(row); callback(); }"
          @edit="onGridEdit"
          @reset="(row, callback) => { callback(); row.Description = {} }"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
        >
          <!-- <template #test="{ row }">
            <vxe-button type="button" content="測試" @click="test(row.Id)"></vxe-button>
          </template> -->
          <template #preview="{ row }">
            <vxe-button type="button" content="預覽" @click="previewEpaper(row.Id)" :disabled="!row.Published"></vxe-button>
          </template>
          <template #send="{ row }">
            <vxe-button type="button" content="查看" @click="sendModal.show(row)" :disabled="!row.Published"></vxe-button>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
            <template #span-text>
              <div class="text-sm ml-10">※各區塊數量上限：會訊文章2則、最新消息5則、會務訊息5則、活動集錦2則</div>
            </template>
            <template #column-photo-and-remark="{ data }">
                <div class="flex">
                  <file-uploader
                    :ref="el => uploader = el"
                    id="epaper-photo-uri"
                    style="min-width: 393px; max-width: 393px; min-height: 150px; max-height: 150px;"
                    class="mb-10"
                    mode="image"
                    :headers="headers"
                    :modelValue="data.Photo?.Uri"
                    :action="uploadAction"
                    :autoUpload="true"
                    :limitedWidth="1200"
                    :limitedHeight="458"
                    @update:modelValue="(value) => { if (data.Photo) data.Photo.Uri = value; else data.Photo = { Uri: value }; }"
                    @filter="uploaderFilter"
                  />
                </div>
              </template>
              <!-- <template #column-publication="{ data }">
                <select-box v-bind="publicationSelectorOptions" v-model="data.PublicationId" />
              </template> -->
              <template #column-bulletin="{ data }">
                <select-box v-bind="bulletinSelectorOptions" v-model="data.MainBulletinId" />
              </template>
              <template #column-bulletins="{ data }">
                <select-box v-bind="bulletinsSelectorOptions" v-model="data.BulletinIds" />
              </template>
              <template #column-conference="{ data }">
                <select-box v-bind="conferenceSelectorOptions" v-model="data.ConferenceBulletinIds" />
              </template>
              <template #column-news="{ data }">
                <select-box v-bind="newsSelectorOptions" v-model="data.NewsBulletinIds" />
              </template>
              <template #column-albums="{ data }">
                <select-box v-bind="albumSelectorOptions" v-model="data.AlbumListIds" />
              </template>
              <!-- <template #column-new-publication="{ data }">
                <select-box v-bind="newPublicationSelectorOptions" v-model="data.NewPublicationIds" />
              </template>
              <template #column-industry-new="{ data }">
                <select-box v-bind="industryNewSelectorOptions" v-model="data.IndustryNewIds" />
              </template>
              <template #column-book="{ data }">
                <select-box v-bind="bookSelectorOptions" v-model="data.BookIds" />
              </template>
              <template #column-media="{ data }">
                <select-box v-bind="mediaSelectorOptions" v-model="data.MediaIds" />
              </template> -->
              <!-- <template #column-template="{ data }">
                <vxe-button v-if="data.Type == 1" type="button" status="primary" content="套用版型" @click="setTemplate(data)"></vxe-button>
                <vxe-button v-if="data.Type == 2" type="button" status="primary" content="套用版型" @click="setActivityTemplate(data)"></vxe-button>
              </template> -->
              <template #html-editer="{ data }">
                <div class="mb-4 wysiwyg" v-if="data.Content">
                  <CKEditor
                    v-model="data.Content.Content"
                    @update:modelValue="value => (data.Content.Content = value)"
                    :editor="classicEditor"
                    :config="editorConfig"
                  />
                </div>
              </template>
              <template #column-user-id="{ data }">
                <select-box class="custom-vue-selector" v-bind="userSelectorOptions" v-model="data.OperatorId" :readonly="true" :disabled="true" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal resize v-model="sendModal.visible" width="80%" title="發行紀錄" height="700" show-zoom="true">
      <SendTable ref="sendGrid" v-bind="sendModal.gridOptions" :modelValue="sendModal.visible" :sid="sendModal.selectedRow.Id" :paperType="sendModal.selectedRow.Type" />
    </vxe-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString';
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import "@/global-components/ckeditor/styles.css";
import SendTable from "@/components/epaper/SendTable.vue";

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SelectBox,
    SendTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const enums = CloudFun.current?.enums as any;
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    const uploader = ref<any>({});

    const gridOptions: GridOptions = {
      stripe: false,
      title: '電子報',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '電子報清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '電子報清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [{
        field: 'Subject',
        title: '電子報主旨',
        width: '45%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: 'Version', title: '版本編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
      { field: 'PublishedDate', title: '發布日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
      { field: 'Published', title: '已發布', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      // {
      //   title: 'test',
      //   width: '100',
      //   slots: { default: "test" }
      // },
      {
        title: '設定',
        width: '100',
        slots: { default: "send" }
      },
      {
        title: '預覽',
        width: '100',
        slots: { default: "preview" }
      },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          return model.dispatch('epaper/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('epaper/query') : undefined,
        save: model ? (params) => model.dispatch('epaper/save', params) : undefined
      },
      modalConfig: { width: "70%", height: "700", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const showEdit = (row: any) => {
      if (row.data.Type == 1) return true;
      return false;
    }

    const changeType = (data: any) => {
      if(data.Type == 1) {
        setTemplate(data);
      } else {
        data.Content.Content = "";
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Subject',
          title: '電子報主旨',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '系統自動產生', clearable: true, disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'Version',
          title: '版本編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'Type',
        //   title: '電子報類型',
        //   span: 12,
        //   itemRender: { name: "$select", options: model ? Object.values(model.enums.EpaperType).map(e => { return { label: e.Name, value: e.Value } }) : [], events: { change: (e: any) => changeType(e.data) } }
        // },
        {
          field: 'PublishedDate',
          title: '發布日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'NoticedTime',
          title: '傳送時間',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'Published',
          title: '已發布',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'OperatorId',
          title: '操作人員',
          span: 12,
          slots: { default: "column-user-id" }
        },
        {
          field: "Photo.Uri",
          title: "橫幅圖片",
          span: 24,
          slots: { default: "column-photo-and-remark" }
          ,visibleMethod: showEdit
        },
        {
          field: 'LinkUrl',
          title: '橫幅鏈結網址',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入網址', clearable: true }, attrs: { type: 'text' } }
          ,visibleMethod: showEdit
        },
        {
          field: 'span-text',
          span: 24,
          slots: { default: "span-text" }
        },    
        // {
        //   field: 'MainBulletinId',
        //   title: '強打報告',
        //   span: 24,
        //   slots: { default: "column-publication" }
        //   ,visibleMethod: showEdit
        // },
        // {
        //   field: 'BulletinId',
        //   title: '精選文章',
        //   span: 24,
        //   slots: { default: "column-bulletin" }
        //   ,visibleMethod: showEdit
        // },
        {
          field: 'BulletinIds',
          title: '會訊文章',
          span: 24,
          slots: { default: "column-bulletins" }
          ,visibleMethod: showEdit
        },
        {
          field: 'NewsBulletinIds',
          title: '最新消息',
          span: 24,
          slots: { default: "column-news" }
          ,visibleMethod: showEdit
        },
        {
          field: 'ConferenceBulletinIds',
          title: '會務訊息',
          span: 24,
          slots: { default: "column-conference" }
          ,visibleMethod: showEdit
        },
        {
          field: 'AlbumListIds',
          title: '活動集錦',
          span: 24,
          slots: { default: "column-albums" }
          ,visibleMethod: showEdit
        },
        // {
        //   field: 'NewPublicationIds',
        //   title: '最新報告',
        //   span: 24,
        //   slots: { default: "column-new-publication" }
        //   ,visibleMethod: showEdit
        // },
        // {
        //   field: 'MediaIds',
        //   title: '影音專區',
        //   span: 24,
        //   slots: { default: "column-media" }
        //   ,visibleMethod: showEdit
        // },
        // {
        //   field: 'BookIds',
        //   title: '專書精選',
        //   span: 24,
        //   slots: { default: "column-book" }
        //   ,visibleMethod: showEdit
        // },
        // {
        //   title: '版型套用',
        //   span: 24,
        //   slots: { default: "column-template" }          
        // },
        { span: 24, slots: { default: 'html-editer' }, visibleMethod: (row: any) => row.data && row.data.Type == 2 ? true : false },
      ],
      rules: {
        Subject: [{ type: 'string', required: true }],
        PublishedDate: [{ required: true }],
        // Author: [{ type: 'string', required: true }],
        // LinkUrl: [{ type: 'string', max: 2000, required: true }],
      }
    }

    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      },
      htmlEmbed: {
            showPreviews: true,
            sanitizeHtml: ( inputHtml: any ) => {
                // Strip unsafe elements and attributes, e.g.:
                // the `<script>` elements and `on*` attributes.
                //const outputHtml = sanitize(inputHtml);
                //console.log(inputHtml)
                return {
                    html: inputHtml,
                    // true or false depending on whether the sanitizer stripped anything.
                    hasChanged: true
                };
            }
        }
    };

    const publicationSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "PublishedDate", title: "出版日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("publication/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Status', Operator.Equal, 1).and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "PublishedDate", order: 1 }];
          return await model!.dispatch("publication/query", params)
        }
      },
    };

    const bulletinSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "Date", title: "日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Author", title: "作者", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("bulletin/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Published', Operator.Equal, true).and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "Date", order: 1 }];
          return await model!.dispatch("bulletin/query", params)
        }
      },
    };

    const bulletinsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      multiselect: true,
      columns: [
        { field: "Date", title: "日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Author", title: "作者", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("bulletin/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Published', Operator.Equal, true).and('DataMode', Operator.Equal, 0).and('Type', Operator.Equal, 3)
          params.sortings = [{ column: "Date", order: 1 }];
          return await model!.dispatch("bulletin/query", params)
        }
      },
    };

    const newsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      multiselect: true,
      columns: [
        { field: "Date", title: "日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Author", title: "作者", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("bulletin/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Published', Operator.Equal, true).and('DataMode', Operator.Equal, 0).and('Type', Operator.Equal, 0)
          params.sortings = [{ column: "Date", order: 1 }];
          return await model!.dispatch("bulletin/query", params)
        }
      },
    };

    const conferenceSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      multiselect: true,
      columns: [
        { field: "Date", title: "日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Author", title: "作者", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("bulletin/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Published', Operator.Equal, true).and('DataMode', Operator.Equal, 0).and('Type', Operator.Equal, 1)
          params.sortings = [{ column: "Date", order: 1 }];
          return await model!.dispatch("bulletin/query", params)
        }
      },
    };

    const albumSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      multiselect: true,
      columns: [
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("pictureAlbum/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Published', Operator.Equal, true).and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "Ordinal", order: 0 }];
          return await model!.dispatch("pictureAlbum/query", params)
        }
      },
    };
    

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("user/find", value), // eslint-disable-line
        query: async(params) => await model!.dispatch("user/query", params) // eslint-disable-line
      }
    };

    const test = (id: any) => {
      if(confirm("確定要發行嗎?")) {
        if(id) {
          model?.dispatch('epaper/send', id).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            //grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          })
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得折扣資料",
          });
        }
      }
    }

    const bookSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "PublishedDate", title: "出版日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("book/find", value), // eslint-disable-line
        query: async(params) => {
          if (params.condition) params.condition.and('Published', Operator.Equal, true)
          else params.condition = new Condition('Published', Operator.Equal, true)
          params.condition.and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "PublishedDate", order: 1 }];
          return await model!.dispatch("book/query", params)
        },
      },
    };

    const mediaSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "PublishedDate", title: "出版日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("media/find", value), // eslint-disable-line
        query: async(params) => {
          if (params.condition) params.condition.and('Published', Operator.Equal, true)
          else params.condition = new Condition('Published', Operator.Equal, true)
          params.condition.and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "PublishedDate", order: 1 }];
          return await model!.dispatch("media/query", params)
        },
      },
    };
    
    const newPublicationSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "PublishedDate", title: "出版日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("publication/find", value), // eslint-disable-line
        query: async(params) => {
          params.condition = new Condition('Status', Operator.Equal, 1).and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "PublishedDate", order: 1 }];
          return await model!.dispatch("publication/query", params)
        }
      },
    };

    const industryNewSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "PublishedDate", title: "出版日期", width: "160", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: async(value) => await model!.dispatch("industryNews/find", value), // eslint-disable-line
        query: async(params) => {
          if (params.condition) params.condition.and('Published', Operator.Equal, true)
          else params.condition = new Condition('Published', Operator.Equal, true)
          params.condition.and('DataMode', Operator.Equal, 0)
          params.sortings = [{ column: "PublishedDate", order: 1 }];
          return await model!.dispatch("industryNews/query", params)
        },
      },
    };

    const setActivityTemplate = (data: any) => {
      if(data.Content) {
        data.Content.Content = `<table>
                                        <tbody>
                                            <tr>
                                                <td style="text-align:center;">
                                                    <p style="margin-left:0;">
                                                        <a href="@網頁版本" target="_blank"><span style="color:#333333;">網頁連結</span></a>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>`;
      }
    }

    const setTemplate = (data: any) => {
      if(data.Content) {
        data.Content.Content = `<div class="raw-html-embed">
    <table class="layout__wrapper" align="center" width="100%" border="0" cellspacing="0" cellpadding="0">
        <tbody>
            <tr class="layout__row">
                <td class="layout__column cLayout--bgColor" align="center" width="100%"
                    style="padding-left:10px;padding:10px;" bgcolor="#ffffff">
                    <div style="margin:0 auto;width:100%;max-width:1200px;">
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0"
                            cellpadding="0">
                            <tbody>
                              <tr>
                                <td class="w_50" align="left" valign="top" width="50%" >
                                  <div style="color:#6a6a6a;font-weight:400;font-size:18px;font-family:'PingFang TC','微軟正黑體','Microsoft JhengHei','Helvetica Neue',Helvetica,Arial,sans-serif;padding:0;margin:0;line-height:1.4">
                                        <span class="a__text" style="color: #EA5514; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                            @發行日期
                                        </span>
                                        發行
                                    </div>
                                </td>                                
                              </tr>
                              <tr>
                                <td class="block-inner__content cBlock--spacingLR w_50" align="right" valign="top" width="50%" bgcolor="#ffffff">
                                  <h4 style="color:#6a6a6a;font-weight:400;font-size:18px;font-family:'PingFang TC','微軟正黑體','Microsoft JhengHei','Helvetica Neue',Helvetica,Arial,sans-serif;padding:0;margin:0;line-height:1.4">
                                    如無法正常顯示 請點選
                                    <a href="@網頁版本" rel="noopener noreferrer" target="_blank" style="font-size: 16px; color: #6a6a6a; text-decoration: underline;">
                                        <span class="a__text" style="color: #6a6a6a; text-decoration: underline; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                網頁版本
                                        </span>
                                    </a>
                                  </h4>
                                </td>
                              </tr>
                              <tr>
                                  <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                      width="100%" bgcolor="#95cef4" style="padding-top:8px;">
                                  </td>
                              </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0"
                            cellpadding="0">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:8px;padding-bottom:8px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td align="center" valign="top" width="100%">
                                                      <a href="#" rel="noopener noreferrer" target="_blank"
                                                          style="size: 16px; color: #333333; text-decoration: none;">
                                                          <span class="a__text"
                                                              style="color: #333333; text-decoration: none; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                              <img class="img_block" border="0" width="1200"
                                                                  src="/Content/images/logo.png"
                                                                  style="display:block;max-width:40%;">
                                                          </span>
                                                      </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0"
                            cellpadding="0">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:8px;padding-bottom:8px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td align="center">
                                                        <p
                                                            style="color:#333333;font-weight:400;font-size:18px;font-family:'PingFang TC','微軟正黑體','Microsoft JhengHei','Helvetica Neue',Helvetica,Arial,sans-serif;padding:0;margin:0;line-height:1.4">
                                                            <a href="@網站連結" rel="noopener noreferrer" target="_blank"
                                                                style="size: 16px; color: #333333; text-decoration: none;">
                                                                <span class="a__text"
                                                                    style="color: #333333; text-decoration: none; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                    首頁 </span> </a>｜ <a href="@聯絡我們"
                                                                rel="noopener noreferrer" target="_blank"
                                                                style="size: 16px; color: #333333; text-decoration: none;">
                                                                <span class="a__text"
                                                                    style="color: #333333; text-decoration: none; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                    聯絡我們 </span> </a>｜<br class="sm_none"
                                                                style="display:none;"> <a href="@取消訂閱"
                                                                rel="noopener noreferrer" target="_blank"
                                                                style="size: 16px; color: #333333; text-decoration: none;">
                                                                <span class="a__text"
                                                                    style="color: #333333; text-decoration: none; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                    取消訂閱 </span> </a><br class="sm_none"
                                                                style="display:none;"> </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0"
                            cellpadding="0">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:8px;padding-bottom:8px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td align="left" valign="top" width="100%"> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-bottom: 16px;">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td align="center" valign="top" width="100%"> <a href="@本週強打.連結"
                                                            rel="noopener noreferrer" target="_blank"
                                                            style="size: 16px; color: #333333; text-decoration: none;">
                                                            <span class="a__text"
                                                                style="color: #333333; text-decoration: none; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                <img class="img_block" border="0" width="1200"
                                                                    src="@本週強打.圖片"
                                                                    style="display:block;max-width:100%;"> </span> </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>                        
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0" cellpadding="0" style="border-top: 2px solid #cccccc;">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR w_50" align="left" valign="top"
                                        width="50%" bgcolor="#ffffff" style="padding-top:8px;padding-bottom:0px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            style="padding-left: 12px;">
                                            <tbody>
                                                <tr>
                                                    <td align="left" valign="top" width="100%"> <a href="@新聞熱搜.連結"
                                                            rel="noopener noreferrer" target="_blank"
                                                            style="size: 26px; color: #333333; text-decoration: underline;">
                                                            <span
                                                                style="font-size: 26px;font-style: italic; color: #333333; text-decoration: underline; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                最新消息 </span> </a>
                                                        <ul style="font-size: 18px; color: #333333; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;"> @新聞熱搜 </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td class="block-inner__content cBlock--spacingLR w_50" align="left" valign="top"
                                        width="50%" bgcolor="#ffffff" style="padding-top:8px;padding-bottom:0px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            style="padding-left: 12px;">
                                            <tbody>
                                                <tr>
                                                    <td align="left" valign="top" width="100%"> <a href="@最新報告.連結"
                                                            rel="noopener noreferrer" target="_blank"
                                                            style="size: 26px; color: #333333; text-decoration: underline;">
                                                            <span
                                                                style="font-size: 26px;font-style: italic; color: #333333; text-decoration: underline; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                會務訊息 </span> </a>
                                                        <ul style="font-size: 18px; color: #333333; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;""> @最新報告 </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0" cellpadding="0" style="border-top: 2px solid #cccccc;">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR w_50" align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:32px;padding-bottom:16px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            style="padding-left: 12px;">
                                            <tbody>
                                                <tr>
                                                    <td align="left" valign="top" width="100%"> <a href="@影音專區.連結"
                                                            rel="noopener noreferrer" target="_blank"
                                                            style="size: 26px; color: #333333; text-decoration: underline;">
                                                            <span
                                                                style="font-size: 26px;font-style: italic; color: #333333; text-decoration: underline; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                活動集錦 </span> </a> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:32px;padding-bottom:16px">
                                        @影音專區 </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0" cellpadding="0" style="border-top: 2px solid #cccccc;">
                            <tbody>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR" align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:32px;padding-bottom:16px">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            style="padding-left: 12px;">
                                            <tbody>
                                                <tr>
                                                    <td align="left" valign="top" width="100%"> <a href="@專書精選.連結"
                                                            rel="noopener noreferrer" target="_blank"
                                                            style="size: 26px; color: #333333; text-decoration: underline;">
                                                            <span
                                                                style="font-size: 26px;font-style: italic; color: #333333; text-decoration: underline; font-family: 'PingFang TC', 微軟正黑體, 'Microsoft JhengHei', Helvetica, Arial, sans-serif;">
                                                                會訊文章 </span> </a> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="block-inner__content cBlock--spacingLR " align="left" valign="top"
                                        width="100%" bgcolor="#ffffff" style="padding-top:32px;padding-bottom:70px">
                                        @專書精選 </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="block-inner" align="center" width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="block-inner__content cBlock--spacingLR " align="center" valign="top" width="100%" bgcolor="#4F4F4F" style="padding: 10px;">
                              <span style="color: #ffffff; font-size: 15px; ">台灣能源技術服務產業發展協會 版權所有 © 2024 TAESCO All Rights Reserved.</span>
                            </td>
                          </tr>
									      </table>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>`
      }
      //classicEditor.editorRef.execute( 'htmlEmbed', '<b>New content.</b>' );
    }

    const sendGrid = ref<any>()
    const sendModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              // .and("EpaperId", Operator.Equal, sendModal.selectedRow.Id.toString())
              .and("DataId", Operator.Equal, sendModal.selectedRow.Id.toString())
              .and("Type", Operator.Equal, enums.MailType.Epaper.Value)
              .and(params.condition!);
             if (!params.sortings || params.sortings?.length === 0)
               params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("mailLog/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('mailLog/query') : undefined,
          save: model ? (params) => model.dispatch('mailLog/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        sendModal.visible = true;
        sendModal.selectedRow = row;
        if (sendGrid.value) sendGrid.value.refresh();
      }
    })

    const previewEpaper = async(id: any) => {
      const url = await model?.dispatch(`epaper/preview`, id).then((data) => {
          return data;
        }).catch((error) =>{
          console.log(error)
        });
      if(url) window.open(url);
    }

    const headers = {
      Authorization: `Basic ${model?.getCertificate(0)}`
    };
    
    return {
      model,
      grid,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      uploader,
      editorConfig,
      classicEditor,
      userSelectorOptions,
      publicationSelectorOptions,
      mediaSelectorOptions,
      bookSelectorOptions,
      newPublicationSelectorOptions,
      industryNewSelectorOptions,
      headers,
      test,
      setTemplate,
      setActivityTemplate,
      sendGrid,
      sendModal,
      previewEpaper,
      bulletinSelectorOptions,
      bulletinsSelectorOptions,
      newsSelectorOptions,
      conferenceSelectorOptions,
      albumSelectorOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("epaper/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
      }
      callback();
    }
  }
})
</script>
<style scoped>
.custom-vue-selector :deep(.vue-tags-input.ti-disabled) {
  background-color: #f3f3f3;
}
.vxe-form--item.is--error .custom-vue-selector :deep(.vue-tags-input .ti-input) {
  border-color: #f56c6c !important;
}

</style>
<style scope>
.ck.ck-balloon-panel {
  z-index: 2000;
}
</style>
